import { useCallback, useState } from 'react';
import { AUTO_GAME_SETTING } from '../../const/DiceConfig';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setDiceAutoDuration, setDiceAutoGame } from '../../store/diceSlice';
import { setLanguage } from '../../store/commonSlice';
import ToggleRed from '../ToggleRed';

export const DiceSetting = () => {
  const [showMenu, setShowMenu] = useState(false);
  const toggleSetting = useCallback(() => {
    setShowMenu((a) => !a);
  }, []);
  return (
    <div
      className='relative'
      onBlur={() => {
        setShowMenu(false);
      }}
    >
      <img src={'assets/dice/gear.svg'} onClick={toggleSetting} />
      {showMenu && <Menu />}
    </div>
  );
};

const Menu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const autoDuration = useSelector((s) => s.dice.autoDuration);
  const isAuto = useSelector((s) => s.dice.isAuto);
  // const lang = useSelector((s) => s.common.lang);
  // const [showLangMenu, setShowlangMenu] = useState(false);
  // const onClickLang = useCallback((langKey) => {
  //   dispatch(setLanguage(langKey));
  //   setShowlangMenu(false);
  // }, []);
  return (
    <div className='absolute right-0 top-full mt-3 flex flex-col rounded-xl bg-black/80 text-white p-3.5 justify-center items-stretch w-[300px] gap-3'>
      <div className='flex flex-row items-center justify-between whitespace-nowrap gap-3'>
        <div className='font-semiBold text-lg'>{t('DiceAutoMode')}</div>
        <ToggleRed
          isLeft={!isAuto}
          toggle={() => {
            dispatch(setDiceAutoGame(!isAuto));
          }}
        />
      </div>
      {isAuto ? (
        <>
          <div className='flex flex-row items-center justify-between whitespace-nowrap gap-3'>
            <div className='font-semiBold text-lg'>
              {t('DiceAutoCountdown')}
            </div>
            <div className='flex-grow'></div>
            <div className='font-semiBold text-lg whitespace-nowrap flex flex-row'>
              <Trans
                i18nKey='DiceAutoSec'
                values={{ num: autoDuration }}
                components={{
                  sec: (
                    <div className='text-[#DE173E] mr-1'>
                      {`${autoDuration} `}
                    </div>
                  ),
                }}
              />
            </div>
          </div>
          <div className='flex flex-row items-center gap-3'>
            <div>{AUTO_GAME_SETTING.GameDurationMin}</div>
            <div className='flex-1 flex items-center'>
              <input
                min={AUTO_GAME_SETTING.GameDurationMin}
                max={AUTO_GAME_SETTING.GameDurationMax}
                type='range'
                className='w-full'
                value={autoDuration}
                onChange={(e) => {
                  dispatch(setDiceAutoDuration(e.target.value));
                }}
              />
            </div>
            <div>{AUTO_GAME_SETTING.GameDurationMax}</div>
          </div>
        </>
      ) : null}
      {/* <div className='flex items-center justify-between text-white'>
        <div className='flex-1'>{t('DiceAutoLang')}</div>
        <div className='relative flex-1 flex flex-row'>
          <LangRow
            lang={lang}
            onClick={() => {
              setShowlangMenu((a) => !a);
            }}
          />
          <img
            alt='down'
            src={'assets/dice/caretDown.svg'}
            className={`${showLangMenu ? 'rotate-180' : 'rotate-0'}`}
          />
          {showLangMenu && <LangMenu onClick={onClickLang} />}
        </div>
      </div> */}
    </div>
  );
};

// const LangMenu = ({ onClick }) => {
//   return (
//     <div className='absolute top-full left-0 right-0 bg-black/80 rounded-xl'>
//       {AUTO_GAME_SETTING.Languages.map(({ key }) => {
//         return <LangRow key={key} lang={key} onClick={() => onClick(key)} />;
//       })}
//     </div>
//   );
// };
// const LangRow = ({ lang, ...props }) => {
//   const langObj = AUTO_GAME_SETTING.Languages.find(({ key }) => key === lang);
//   return (
//     <div className='flex flex-row items-center p-2 gap-2' {...props}>
//       <img src={langObj?.icon} />
//       <div>{langObj?.text}</div>
//     </div>
//   );
// };
