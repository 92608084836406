import { createSlice } from '@reduxjs/toolkit';
import commonSlice, { GameStatus } from './commonSlice';
import { AUTO_GAME_SETTING } from '../const/DiceConfig';

const initialState = {
  roundId: 0,
  historyTotal: 0,
  status: GameStatus.UNKNOWN,
  result: null,
  autoDuration: Math.floor(
    (AUTO_GAME_SETTING.GameDurationMin + AUTO_GAME_SETTING.GameDurationMax) / 2
  ),
  isAuto: false,
};

export const setDiceResult = (a) => diceSlice.actions.setResult(a);
export const setDiceStatus = (a) => diceSlice.actions.setStatus(a);
export const setDiceAutoGame = (a) => diceSlice.actions.setDiceAutoGame(a);
export const setDiceAutoDuration = (a) =>
  diceSlice.actions.setDiceAutoDuration(a);

export const diceSlice = createSlice({
  name: 'dice',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setResult: (state, action) => {
      state.result = action.payload;
    },
    setDiceAutoDuration: (state, action) => {
      state.autoDuration = action.payload;
    },
    setDiceAutoGame: (state, action) => {
      state.isAuto = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(commonSlice.actions.setStatus, (state, action) => {
      state.status = action.payload;
    });
    builder.addCase(commonSlice.actions.setHistory, (state, action) => {
      console.log('redux history', action);
      try {
        const { total, sessions } = action.payload;
        state.historyTotal = total;
        const lastResult = sessions[0];
        state.result = lastResult;
        console.log('Log:lastResult', lastResult);
        state.roundId = state.historyTotal || 0;
      } catch (e) {
        console.error('redux history', e);
      }
    });
  },
});
export default diceSlice;
